<script>
import Repository from "@/repositories/Repository.vue";
import Enum from "@/helpers/Enum.vue";

const Post_CreateTransactionFund = async (id,typeUpdate,fullName, request) => {
  return await Repository.post(Enum.API.Mifos.CreateTransactionFund + `?Id=${id}&TypeUpdate=${typeUpdate}&fullName=${fullName}`, request);
};
const Post_UndoTransactionFund = async (id, request) => {
  return await Repository.post(Enum.API.Mifos.UndoTransactionFund + "/?id=" + id, request);
};
export default {
  Post_CreateTransactionFund,
  Post_UndoTransactionFund
};
</script>