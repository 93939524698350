<template>
  <div class="file-item">
    <img
      class="image-thumbnail"
      @click="isZoom = true"
      :src="Path"
      :data-source="Path"
    />
    <div class="btn-clear" @click="$emit('deleteFile', file)" v-if="IsShow">
      <b-icon icon="minus-circle" type="is-danger"> </b-icon>
    </div>
  </div>
</template>

<script>
var bucketName = process.env.VUE_APP_AWS_BucketName;
var amzUrl = process.env.VUE_APP_STATIC_DOMAIN;
import SignUrl from "@/helpers/SignUrl.vue";
import ToastHelper from "@/helpers/OpenToast.vue";
// import FundTransferService from "@/modules/fundtransfers/services/FundServices.vue";
//import UploadAWS from "@/helpers/UploadAWS.vue";
// import UserService from "@/modules/user/services/UserService.vue";
//import ToastHelper from "@/helpers/OpenToast.vue";
// import store from "@/store";
export default {
  props: ["file", "idx", "IsShow"],
  data() {
    return {
      bucketName: bucketName,
      amzUrl: amzUrl,
      imgUrl:
        "2021/05/Customers/hosokhachhang/nhanthan/cmnd/20210531_151029_1622448629_1aca.png",
      isZoom: false,
      image: "",
      urlImage: "",
      Path: ""
    };
  },
  async mounted() {
    // this.getImage();
    var _self = this;

    let response = await SignUrl.Get_SignUrl(_self.file.Path);
    if(response != null){
      _self.Path = response;
    }else{
      ToastHelper.OpenToast(
          "Đã có lỗi xảy ra",
          "is-danger"
        );
    }
  },
  methods: {
    // async getImage() {
    //   debugger
    //   let _self = this;
    //   var url = _self.file.Path;
    //   let authKey = store.token;
    //   if (!authKey) {
    //     authKey = UserService.authHeader();
    //   }
    //   _self.image = await FundTransferService.GetImageFromS3(url);
    // }
  },
};
</script>
<style lang="scss" scoped>
.image-thumbnail {
  height: 80px;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 10px;
}
.file-item .btn-clear {
  position: absolute;
  cursor: pointer;
  right: -12px;
  top: 2px;
  z-index: 10;
  cursor: pointer;
  background: $color-white;
  border-radius: 12px;
  height: 24px;
  width: 24px;
}
.wrap-img {
  display: flex;
  justify-content: center;
}
</style>